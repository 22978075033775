.author-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  
  .author-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  
  .author-logo {
    width: 35px;
    height: 35px;
  }
  
  .author-text {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }