.machine-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.count-container {
  display:flex;
  flex-direction: row;
  align-items: bottom;
  gap: 10px;
}

.mint-text {
  font-size: 32px;
  font-weight: bold;
  margin: 5px;
}

.padding {
  padding-left: 50px;
  padding-bottom: 50px;
}

.dropdate-text {
  font-size: 24px;
  color: green;
  padding-bottom: 25px;
}

.success-text {
  font-size: 32px;
  color: greenyellow;
}

.failure-text {
  font-size: 32px;
  color: orangered;
}

.nft-container {
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin: 0;
  padding: 20px 0 20px 0;
}

.nft-grid .nft-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.nft-item img {
  width: 100%;
  height: 700px;
  border-radius: 10px;
  object-fit: cover;
}

@font-face {
  font-family: press_start;
  src: url("./PressStart2P-Regular.ttf");
}

.sub-header-text {
  font-family: press_start;
  font-size: 32px;
  padding-top: 70px;
  padding-bottom: 20px;
}
