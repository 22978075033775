@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  min-height: 90vh;
  background-color: rgb(20, 20, 20);
  text-align: center;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  color: white;
  position: relative;
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.sub-text {
  font-size: 25px;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: 350px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(left, #ff8867, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button:disabled {
  background:  #fff;
  color: #ccc;
  cursor: no-drop;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connected-container input[type='text'] {
  display: inline-block;
  padding: 10px;
  width: 50%;
  height: 60px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  margin: 50px auto;
}

.connected-container button {
  height: 50px;
}

@font-face {
  font-family: press_start;
  src: url("./PressStart2P-Regular.ttf");
}

.header-titles {
  padding-top: 50px;
  color: white;
  text-align: center;
}

.header-text {
  font-family: press_start;
  font-size: 40px;
}

.header-sub-text {
  font-size: 30px;
  padding: 20px;
}

.wallet-info {
  color: white;
  text-align: center;
  width: 160px;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  border: 0;
  border-radius: 5px;
}

.wallet-address {
  background-color: #c267ee;
}

.wallet-disconnect {
  animation: gradient-animation 4s ease infinite;
  background-image: linear-gradient(to right, #c267ee 0%, #51daec 100%);
  cursor: pointer;
}

.wallet-connect {
  animation: gradient-animation 4s ease infinite;
  background-image: linear-gradient(to right, #c267ee 0%, #51daec 100%);
  cursor: pointer;
}

.wallet-info-container {
  position: absolute;
  top: 65px;
  right: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
